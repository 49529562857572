if ("NodeList" in window && !NodeList.prototype.forEach) {
  console.info("polyfill for IE11");

  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;

    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

(function ($) {
  //document.querySelector("video").play();
  $(".fancybox").fancybox();
  const open_search = document.getElementById("open-search");

  if (open_search) {
    open_search.addEventListener("click", function () {
      document.querySelector(".header__search-form").classList.toggle("open");
    });
    document.querySelector(".search-overlay").addEventListener("click", function () {
      document.querySelector(".header__search-form").classList.remove("open");
    });
  }

  const mobile_nav = document.getElementById("mobile-nav");

  if (mobile_nav) {
    document.querySelector(".mobile-nav__close").addEventListener("click", function () {
      mobile_nav.classList.remove("open");
      document.querySelector("body").classList.remove("lock-scroll");
    });
    document.querySelector(".mobile-nav__overlay").addEventListener("click", function () {
      mobile_nav.classList.remove("open");
      document.querySelector("body").classList.remove("lock-scroll");
    });
    const fixed_bar_menu = document.querySelector(".fixed-bar__menu");

    if (fixed_bar_menu) {
      fixed_bar_menu.addEventListener("click", function () {
        mobile_nav.classList.add("open");
        document.querySelector("body").classList.add("lock-scroll");
      });
    }
  }

  const mini_cart = document.getElementById("mini-cart");

  if (mini_cart) {
    const bar_cart = document.querySelector(".fixed-bar__cart");

    if (bar_cart) {
      document.querySelector(".fixed-bar__cart").addEventListener("click", function () {
        document.getElementById("mini-cart").classList.add("open");
        document.querySelector("body").classList.add("lock-scroll");
      });
    }

    const header_cart = document.querySelector(".header__cart");

    if (header_cart) {
      document.querySelector(".header__cart").addEventListener("click", function () {
        document.getElementById("mini-cart").classList.add("open");
        document.querySelector("body").classList.add("lock-scroll");
      });
    }

    document.querySelector(".mini-cart__close").addEventListener("click", function () {
      document.getElementById("mini-cart").classList.remove("open");
      document.querySelector("body").classList.remove("lock-scroll");
    });
    document.querySelector(".mini-cart__overlay").addEventListener("click", function () {
      document.getElementById("mini-cart").classList.remove("open");
      document.querySelector("body").classList.remove("lock-scroll");
    });
  }

  $(".hero-carousel__slider").slick({
    arrows: false,
    dots: true,
    fade: true
  });
  $(".woocommerce-product-gallery__slider").slick({
    dots: true,
    fade: true
  });
  $(".product-shelf__carousel ul").slick({
    slidesToShow: 4,
    responsive: [{
      breakpoint: 640,
      settings: "unslick"
    }]
  });
  $(".brand-featured .woocommerce .products").slick({
    slidesToShow: 2,
    variableWidth: true,
    appendArrows: $(".brand-featured__arrows"),
    responsive: [{
      breakpoint: 640,
      settings: "unslick"
    }]
  });
  $("body").on("click", "form.cart button.plus, form.cart button.minus", function () {
    // Get current quantity values
    var qty = $(this).closest(".quantity").find(".qty");
    var val = parseFloat(qty.val());
    var max = parseFloat(qty.attr("max"));
    var min = parseFloat(qty.attr("min"));
    var step = parseFloat(qty.attr("step")); // Change the value if plus or minus

    if ($(this).is(".plus")) {
      if (max && max <= val) {
        qty.val(max);
      } else {
        qty.val(val + step);
      }

      $(this).siblings(".minus").attr("disabled", false);
    } else {
      if (min && min >= val) {
        qty.val(min);
        $(this).attr("disabled", true);
      } else if (val > 1) {
        qty.val(val - step);
        $(this).attr("disabled", false);
      }
    }

    qty.trigger("change");
  });
  $("body").on("change", "form.cart .qty", function () {
    var thisVal = parseFloat($(this).val());
    console.log(thisVal);
    $("#update_cart").attr("disabled", false);

    if (thisVal > 1) {
      $(this).siblings(".minus").attr("disabled", false);
    } else {
      $(this).siblings(".minus").attr("disabled", true);
    }
  });
  const eyeMarkup = document.getElementById("eyeicon").innerHTML;
  const quick_button = document.querySelectorAll(".yith-wcqv-button");

  if (quick_button) {
    for (let i = 0; i < quick_button.length; i++) {
      quick_button[i].innerHTML = eyeMarkup;
    }
  }

  $(".button yith-wcqv-button").html(eyeMarkup);
  const acc_btns = document.querySelectorAll(".svp-accordion__button");
  const acc_items = document.querySelectorAll(".svp-accordion__item");

  if (acc_btns) {
    for (let i = 0; i < acc_btns.length; i++) {
      acc_btns[i].addEventListener("click", function (event) {
        event.preventDefault();

        if (this.closest(".svp-accordion__item").classList.contains("active")) {
          this.closest(".svp-accordion__item").classList.remove("active");
        } else {
          for (let a = 0; a < acc_items.length; a++) {
            acc_items[a].classList.remove("active");
          }

          this.closest(".svp-accordion__item").classList.add("active");
        }
      });
    }
  }

  $(document.body).on("added_to_cart", function () {
    document.getElementById("mini-cart").classList.add("open");
    document.querySelector("body").classList.add("lock-scroll");
  });

  function scrollDown() {
    var win_width = window.innerWidth;
    var top = window.scrollY; //Header animation

    const headerEl = document.getElementById("header");

    if (win_width > 600) {
      if (top > 280) {
        headerEl.classList.add("scroll"); //headerLogo.setAttribute('src',logoData);
      } else {
        headerEl.classList.remove("scroll"); //headerLogo.setAttribute('src',logoSrc);
      }
    } //Scroll animations


    var animatedEl = document.getElementsByClassName("animate-fade");
    var h = window.innerHeight;
    var i;

    for (i = 0; i < animatedEl.length; i++) {
      var viewportOffset = animatedEl[i].getBoundingClientRect();
      var topPos = viewportOffset.top;

      if (topPos <= h * 0.8) {
        animatedEl[i].classList.add("fade-in");
      }
    } // Lazy Load


    var lazy_image = document.querySelectorAll("[data-src]");

    if (lazy_image) {
      //console.log(lazy_image);
      for (var l = 0; l < lazy_image.length; l++) {
        var data_src = lazy_image[l].dataset.src;
        var viewportOffset = lazy_image[l].getBoundingClientRect();
        var topPos = viewportOffset.top;

        if (topPos <= h * 1.3) {
          lazy_image[l].setAttribute("src", data_src);
          lazy_image[l].removeAttribute("data-src");
          lazy_image[l].classList.add("loaded");
        }
      }
    } // Lazy Background


    var lazy_bg = document.querySelectorAll("[data-bg]");

    if (lazy_bg) {
      //console.log(lazy_bg);
      for (var l = 0; l < lazy_bg.length; l++) {
        var data_src = lazy_bg[l].dataset.bg;
        var viewportOffset = lazy_bg[l].getBoundingClientRect();
        var topPos = viewportOffset.top;

        if (topPos <= h * 1.3) {
          lazy_bg[l].setAttribute("style", "background-image:url(" + data_src + ")");
          lazy_bg[l].removeAttribute("data-bg");
          lazy_bg[l].classList.add("loaded");
        }
      }
    }
  }

  setTimeout(function () {
    document.querySelector("body").classList.add("loaded");
  }, 800);
  scrollDown();
  window.addEventListener("scroll", function (e) {
    scrollDown();
  });
})(jQuery);

const play_video_button = document.getElementById("play-video-button");

if (play_video_button) {
  const video_modal = document.getElementById("video-modal");
  const video_url = video_modal.dataset.video;
  const video_el = `<video id="video-modal-video" src="${video_url}" controls playsinline /> `;
  const video_modal_content = video_modal.querySelector(".video-modal__content");
  video_modal_content.innerHTML = video_el;
  play_video_button.addEventListener("click", () => {
    document.getElementById("video-modal-video").play();
    video_modal.classList.add("open");
  });
  const close_video = document.querySelector(".video-modal__close");
  close_video.addEventListener("click", () => {
    video_modal.classList.remove("open");
    document.getElementById("video-modal-video").pause();
    document.getElementById("video-modal-video").currentTime = 0;
  });
}